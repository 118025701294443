export const m = {
    notfound: {
        title: "404",
        header1: "Page Not Found",
        header2: "Met Wrong Person At Wrong Time\nJust Lik U and Me",
        button: "Back Home"
    },
    spage: "‘s Page",
    visitor:"Yo👋 ur the {number} ‘s visitor",
    tag: {
        username: 'Username',
        nickname: 'Nickname',
        occupation: 'Occupation',
        location: 'Location',
        bio: 'Bio',
        bilibili: 'Bilibili',
        douyin: 'Douyin',
        email: 'Email',
        facebook: 'Facebook',
        instagram: 'Instagram',
        line: 'Line',
        linkedin: 'LinkedIn',
        messages: 'Messages',
        messenger: 'Messenger',
        msg: 'Message',
        paypal: 'PayPal',
        redbook: 'RED',
        snapchat: 'Snapchat',
        spotify: 'Spotify',
        telegram: 'Telegram',
        tiktok: 'TikTok',
        twitch: 'Twitch',
        twitter: 'Twitter',
        wechat: 'WeChat',
        weibo: 'Weibo',
        whatsapp: 'WhatsApp',
        youtube: 'Youtube',
        zenly: 'Zenly',
        phone: 'Contact',
    },
}