import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  routes: routes
})

//路由前置守卫相关操作
router.beforeEach((to, from, next) => {
  next()  
})

export default router