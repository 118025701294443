import Vue from "vue";

import "vant/lib/index.css"; // 引入全部样式
import "vant/lib/index.less";
import '@/assets/style/app.css';

import App from "./App.vue";
import routers from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import ScrollView from "vue-scrollview";

import { Grid, GridItem } from "vant";
import { Image as VanImage } from "vant";
import { Popup } from "vant";
import { Col, Row } from "vant";
import { Skeleton } from "vant";
import { Button } from "vant";
import { Form } from "vant";
import { Field, Toast } from "vant";
import { Step, Steps } from "vant";
import { Cell, CellGroup } from "vant";
import { Uploader } from "vant";
import { Dialog } from "vant";
import { Switch } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Lazyload } from 'vant';
import { Tab, Tabs } from 'vant';



import Clipboard from "v-clipboard";

import VueFab from "vue-float-action-button";

Vue.config.productionTip = false;

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Skeleton);
Vue.use(VueI18n);
Vue.use(ScrollView);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Uploader);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);

Vue.use(Clipboard);

Vue.use(VueFab, {
  // opitons 可选iconfont图标或MaterialIcons
  iconType: 'MaterialDesign'
  // iconType: "iconfont",
});

// 全局注册

const i18n = new VueI18n({
  locale: "en-US", // 语言标识
  messages: {
    "zh-CN": require("./lang/zh-CN"), // 中文语言包
    "en-US": require("./lang/en-US"), // 英文语言包
  },
});

new Vue({
  el: "#app",
  router: routers,
  store,
  i18n,
  render: (c) => c(App),
});
