<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    // 语言处理
    let lang = (navigator.language || navigator.browserLanguage).toLowerCase();
    console.log(lang);
    switch (lang) {
      case "zh-cn":
        return (this.$i18n.locale = "zh-CN");
      case "zh-tw":
        return (this.$i18n.locale = "zh-CN");
      case "zh-hk":
        return (this.$i18n.locale = "zh-CN");
    }
  },
};
</script>

<style>
body {
  font-family: "Rubik", sans-serif !important;
}
::-webkit-scrollbar {
  display: none;
}
#app {
  color: #2c3e50;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* padding: 26px 20px 20px; */
  background: #f7f8fa;
}
/* #nav {
  padding: 30px;
} */
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.cart {
  position: fixed !important;
  right: 30px;
  bottom: 30px;
  z-index: 9999;
  font-size: 60px !important;
}
.errormsgtitle {
  margin: 10px 0 0 0;
  font-weight: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: left;
}
.title {
  margin: 10px 0 0 0;
  font-weight: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: left;
}
.desc {
  margin: 0 0 10px;
  color: rgba(69, 90, 100, 0.6);
  font-size: 14px;
  text-align: left;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.9);
}
.today-t {
  margin: 20px 0 20px 0;
  font-weight: normal;
  font-weight: 600;
  font-size: 27px;
  text-align: left;
}
.ma-10 {
  margin: 5px;
}
.price {
  color: #ee0a24;
  font-weight: 500;
  font-size: 22px;
}
</style>
