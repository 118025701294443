export const m = {
    notfound: {
        title: "肆零四",
        header1: "找不到訪問頁面",
        header2: "在错的时间遇见错的人\n就像我遇见你",
        button: "返回首页"
    },
    spage: "的页面",
    visitor:"你好👋 第 {number} 个访问者",
    tag: {
        username: '用户名',
        nickname: '昵称',
        occupation: '职业',
        location: '地区',
        bio: '简介',
        bilibili: '哔哩哔哩',
        douyin: '抖音',
        email: '邮箱',
        facebook: 'Facebook',
        instagram: 'Instagram',
        line: 'Line',
        messages: 'Messages',
        messenger: 'Messenger',
        msg: '短信',
        paypal: 'PayPal',
        linkedin: '领英',
        redbook: '小红书',
        snapchat: 'Snapchat',
        spotify: 'Spotify',
        telegram: 'Telegram',
        tiktok: 'TikTok',
        twitch: 'Twitch',
        twitter: 'Twitter',
        wechat: '微信',
        weibo: '微博',
        whatsapp: 'WhatsApp',
        youtube: 'Youtube',
        zenly: 'Zenly',
        phone: '联系人',
    },
    hint: {
        bilibili: '请输入哔哩哔哩账号 Uid',
        wechat: '请输入微信号',
        weibo: '请输入完整微博分享链接',
        douyin: '请输入完整抖音分享链接',
        email: '请输入邮箱',
        facebook: '请输入完整 Facebook 个性化链接',
        instagram: '请输入 Instagram 用户名',
        line: '请输入 Line ID',
        linkedin:'请输入完整 LinkedIn 链接',
        messenger: '请输入完整 Facebook 个性化链接',
        phone: '请输入完整手机号',
    }
}