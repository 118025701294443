export default [
  {
    path: "/",
    // redirect: "/about",
  },
  {
    path: "/about",
    name: "About",
    // beforeEnter() {
    //   window.location.href = "https://buy.justap.club";
    // },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/Login/Login"),
  },
  {
    path: "/me",
    name: "Mine",
    component: () => import("@/components/Me"),
  },
  {
    path: "/anlytic",
    name: "Anlytic",
    component: () => import("@/components/Anlytic"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("@/components/Shop"),
  },
  {
    path: "/hi/:tagid",
    name: "Tag",
    component: () => import("@/components/Hi/Tag"),
  },
  {
    path: "/newtag",
    name: "NewTag",
    component: () => import("@/components/Hi/NewTag"),
  },
  {
    path: "/edit/:tagid",
    name: "Edit",
    component: () => import("@/components/Edit"),
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/components/Error"),
  },
];
